import { Controller } from 'stimulus';
var table;

export default class extends Controller {
  static targets = ['table', 'file'];

  connect() {
  }

  async upload(event) {
    const file = event.target.files[0];
    $('.excel #filename').html(event.target.files[0].name);

    const formData = new FormData();
    formData.append('file', file);

    fetch('/sms_message_excel', {
      method: 'POST',
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      }
    })
    .then(response => response.json())
    .then(data => {
      // Handle response data as needed
      
      table = new DataTable('.data-table-sms')
      table.destroy();
      this.tableTarget.innerHTML = data.rows ;
      
      $(".clear-table-button").removeClass('d-none');
      $(".import-length-container").removeClass('d-none');
      $(".import-length").text(data.size);
      table = $('.data-table-sms').dataTable( {
        "ordering": false,
        "paging": $('.data-table.no-pagination').length == 0,
        "language": {
          "url": '//cdn.datatables.net/plug-ins/1.13.7/i18n/pt-PT.json',
        }
      } );
    })
    .catch(error => console.error('Error:', error));
    
  }
  
  async changeTab(event) {
    const tab = event.currentTarget.dataset.tab;
    $('.sms-table').addClass('d-none');
    $('.sms-table.' + tab).removeClass('d-none');
  }

  async clearTable() {
    $(".clear-table-button").addClass('d-none');
    $(".import-length-container").addClass('d-none');
    $(".import-length").text("");

    table.dataTable().fnDestroy();
    $('.excel #file').val("");
    $('.excel #filename').html("");
    this.tableTarget.innerHTML = "" ;

    table = $('.data-table-sms').dataTable( {
      "ordering": false,
      "paging": $('.data-table.no-pagination').length == 0
    } );
  }
  
  async selectAll(event) {
    const tab = event.currentTarget.dataset.tab;
    const checked = event.currentTarget.checked;
    $("." + tab + " .sms-option").prop('checked', checked);
  }
}