// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "bootstrap/dist/js/bootstrap";
window.bootstrap = require("bootstrap/dist/js/bootstrap");
import "@fortawesome/fontawesome-free";

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import './controllers'
import './confirm.js';
import Vote from './vote.js';
const vote = new Vote();
var table;
var poll_interval;

$(document).on("turbolinks:load", function() {

  $('[data-bs-toggle="tooltip"]').tooltip();
  
  $('.dataTables_paginate,.dataTables_info').remove();
  table = $('.data-table').dataTable( {
    "ordering": false,
    "paging": $('.data-table.no-pagination').length == 0,
    "language": {
      "url": '//cdn.datatables.net/plug-ins/1.13.7/i18n/pt-PT.json',
    }
  } );

  $(".nav-link-user").on("click", function(e){
    e.preventDefault();

    // hide components
    $(".sidebar .component").addClass("d-none");
    // show selected component
    $(".sidebar " + $(this).find('.nav-link').attr("href")).removeClass("d-none");
    
    // unselect nav-links
    $(".nav-item.nav-link-user").removeClass("active");
    // select clicked nav-link
    $(this).addClass("active");
    $(this).removeClass("notify");

    $(".sidebar-container").addClass("col-4").removeClass("col-0");

    openFunction($(this).find('.nav-link').attr("href"));
  });
  
  $(".toggle-side-bar").on("click", function(e){
    $(".sidebar-container").toggleClass("col-4 col-0");
  });

  $('.admin-form-modal form').on('submit', function(){
    $('.admin-form-modal').modal('hide');
  });

  $('.presence-warning').on('click', function(){ 
    $('body .container-fluid').prepend('<div class="alert alert-danger" role="alert">\
      <span class="close-alert position-absolute fs-4 end-0 me-2" role="button" aria-hidden="true">&times;</span>\
      <strong>Não lhe foi marcada presença!</strong><br>\
      Não poderá pedir para falar nem votar.Por favor entre em contacto com a adminsitração.</div>');
  });
  
  $('.admin-check-box').on('change', function(){
    let $admin_check_container = $(this).parents('.admin-check-container');
    if ($(this).is(":checked")){
      $admin_check_container.find('.yes-label').removeClass('d-none');
      $admin_check_container.find('.no-label').addClass('d-none');
    }
    else{
      $admin_check_container.find('.yes-label').addClass('d-none');
      $admin_check_container.find('.no-label').removeClass('d-none');
    }
  });

  $(".datatable-search").keyup(function(e) {
    table.api().search( this.value ).draw();
  });

  $(".datatable-search-user-list").keyup(function(e) {
    $('.datatable-user-list').dataTable().api().search( this.value ).draw();
  });

  $('.datatable-search, .datatable-search-user-list').on('keypress', function(e) {
    return e.which !== 13;
  });
  

  $(document).on('click', '.next-tab', function(){
    $(".poll-select.voters").click();
  });

  $(document).on('click', '.close-alert', function(){
    $(".close-alert").parents(".alert").remove();
  });

  $(document).on('click', '.custom-table-row', function(){
    let checkbox = $(this).find('.sms-option')
    checkbox.prop('checked', !checkbox.prop('checked'));
  });

  $(document).on('click', '.sms-option', function(e){
    e.stopPropagation();
  });
  
  setTimeout(()=>{
    $(".alert").remove();
  }, 3000);

  $('.select-vote').on('click', function(){
    vote.openVote($(this).data('vote-id'));
    clearInterval(poll_interval);
    poll_interval = setInterval( ()=>{ vote.openVote($(this).data('vote-id')) }, 10000);
  });

});


function openFunction(target){
  
  switch (target) {
    case "#poll":
      vote.openVote();
      break;
    default:
      break;
  }
}