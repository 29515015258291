
var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

$(document).on("turbolinks:load", function() {
  $(document).on('click', '.confirm-trigger-button', function(e){
    e.preventDefault();
    e.stopPropagation();
    $('#confirmationModal .confirmation-text').text($(this).data('text'));
    $('#confirmationModal .confirm-button').attr('href', $(this).data('href'));
    $('#confirmationModal').modal('show');
  });

  $(document).on('click', '.confirm-submit-button', function(e){
    e.preventDefault();
    e.stopPropagation();
    $('#confirmationSendModal .confirmation-text').text($(this).data('text'));
    $('#confirmationSendModal .sms-text').text('"'+ $('.sms-text-message').val() +'"');
    $('#confirmationSendModal .sms-users').text('Para '+ $('.sms-table:not(.d-none) tbody input:checked').length +' destinatários?');
    
    $('#confirmationSendModal .confirm-button').off('click');
    let target = $(this).data('target');
    $('#confirmationSendModal .confirm-button').on('click', function () {
      $(target).click();
    });
    $('#confirmationSendModal').modal('show');
  });
});