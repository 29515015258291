import { Controller } from 'stimulus';
var table;
var smsTable;

export default class extends Controller {
  static targets = ['table', 'file', 'tableIndex', 'title', 'smsText'];

  connect() {
  }

  async newPoll() {
    console.log(this);
    this.tableIndexTarget.value = "";
    this.titleTarget.value = "";
    this.smsTextTarget.value = "";

    document.getElementById('editPollModalLabel').classList.add('d-none');
    document.getElementById('addPollModalLabel').classList.remove('d-none');
  }
  
  async fetchPoll(event) {
    let parentRow = event.target.parentNode.closest('.custom-table-row');
    console.log(parentRow);
    this.tableIndexTarget.value = parentRow.querySelector('.option-index').value;
    this.titleTarget.value = parentRow.querySelector('.option-title').value;
    this.smsTextTarget.value = parentRow.querySelector('.option-sms-text').value;

    document.getElementById('editPollModalLabel').classList.remove('d-none');
    document.getElementById('addPollModalLabel').classList.add('d-none');
  }

  async savePollUser(event) {
    let target = $(event.target);
    let parent = target.parents('tr')
    
    const id = event.currentTarget.dataset.id;
    const formData = new FormData();
    if (event.currentTarget.dataset.resend){
      formData.append('resend', '1');
      parent.find('.resend-poll-user').addClass('pe-none opacity-50');
    }
    else{
      formData.append('phone', parent.find('.poll-user-phone').val());
      parent.find('.resend-poll-user').removeClass('pe-none opacity-50');
    }
    
    let statusClass ="";
    let statusTitle ="";
    fetch('/poll_users/' + id , {
      method: 'PUT',
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      }
    })
    .then(response => { 
      if (response.status == 200 ){
        statusClass = "success";
        statusTitle = "Successo";
        
        parent.find('.poll-user-phone').addClass('pe-none border-0');
        parent.find('.save-poll-user').addClass('d-none');
        parent.find('.edit-poll-user').removeClass('d-none');
      }
      else{
        statusClass = "danger";
        statusTitle = "Erro";
      }
      return response}) // output the status and return response
    .then(response => response.text()) 
    .then((response) => {
      console.log(response)
      console.log(statusClass)
      console.log(statusTitle)
      console.log(JSON.parse(response))

      $('body .container-fluid').prepend('<div class="alert position-fixed alert-'+ statusClass +'" role="alert">\
      <span class="close-alert position-absolute fs-4 end-0 me-2" role="button" aria-hidden="true">&times;</span>\
      <strong>'+ statusTitle +'</strong><br>\
      ' + JSON.parse(response).message + '</div>');

      setTimeout(()=>{
        $(".alert").remove();
      }, 3000);
    })

  }

  async editPollUser(event) {
    let target = $(event.target);
    let parent = target.parents('tr')
    parent.find('.poll-user-phone').removeClass('pe-none border-0');
    parent.find('.edit-poll-user').addClass('d-none');
    parent.find('.save-poll-user').removeClass('d-none');
  }

  async fetchMessages(event) {
    const id = event.currentTarget.dataset.id;
    const response = await fetch('/polls/' + id );
    const data = await response.json();

    smsTable = new DataTable('.poll-sms-table')
    smsTable.destroy();
    $('.poll-sms-table tbody').html("");
    $('.sms-view-text').text("");
    $('.sms-view-title').text("");
    
    console.log(data.poll_users);
    
    let runningPoll = false
    if (data.status == 1){
      runningPoll = true
    }
    
    $('.sms-view-text').text('"' + data.message + '"');
    $('.sms-view-title').text("Ver votação - " + data.title);

    data.poll_users.forEach(element => {
      let new_row = $("<tr></tr>");
      new_row.append("<td>" + element.name + "</td>");
      new_row.append("<td><input type='text' class='poll-user-phone form-control text-black pe-none border-0' value='" + element.phone + "'></td>");
      new_row.append("<td>" + element.partner_number + "</td>");
      new_row.append("<td>" + element.work_place + "</td>");
      new_row.append("<td>" + element.delivery_status + "</td>");

      if (runningPoll){
        new_row.append("<td>\
          <a class='btn text-decoration-none edit-poll-user' data-action='click->poll#editPollUser' data-id='" + element.id + "'><i class='fas fa-pencil'></i></a>\
          <a class='btn text-decoration-none save-poll-user d-none' data-action='click->poll#savePollUser' data-id='" + element.id + "'><i class='fas fa-save'></i></a>\
          <a class='btn text-decoration-none resend-poll-user' data-action='click->poll#savePollUser' data-id='" + element.id + "' data-resend='1'><i class='fas fa-refresh'></i></a>\
          </td>");
      }
      else{
        new_row.append("<td></td>");
      }
      $('.poll-sms-table tbody').append(new_row);
    });
    
    smsTable = $('.poll-sms-table').dataTable({
      "language": {
        "url": '//cdn.datatables.net/plug-ins/1.13.7/i18n/pt-PT.json',
      }
    });
  }
  
  async selectTab(event) {
    const tab = event.currentTarget.dataset.tab;
    console.log(tab);

    $(".poll-select").removeClass('active');
    $(".poll-select." + tab).addClass('active');
    $(".poll-tab").removeClass('active');
    $(".poll-tab." + tab).addClass('active');
  }
  
  async upload(event) {
    const file = event.target.files[0];
    $('.excel #filename').html(event.target.files[0].name);

    const formData = new FormData();
    formData.append('file', file);

    fetch('/sms_message_excel', {
      method: 'POST',
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      }
    })
    .then(response => response.json())
    .then(data => {
      // Handle response data as needed
      table = new DataTable('.data-table-sms')
      table.destroy();
      this.tableTarget.innerHTML = data.rows ;
      
      $(".clear-table-button").removeClass('d-none');
      $(".import-length-container").removeClass('d-none');
      $(".import-length").text(data.size);
      table = $('.data-table-sms').dataTable( {
        "ordering": false,
        "paging": $('.data-table.no-pagination').length == 0,
        "language": {
          "url": '//cdn.datatables.net/plug-ins/1.13.7/i18n/pt-PT.json',
        }
      } );
    })
    .catch(error => console.error('Error:', error));
    
  }
  
  async changeTab(event) {
    const tab = event.currentTarget.dataset.tab;
    $('.sms-table').addClass('d-none');
    $('.sms-table.' + tab).removeClass('d-none');
  }

  async clearTable() {
    $(".clear-table-button").addClass('d-none');
    $(".import-length-container").addClass('d-none');
    $(".import-length").text("");

    table.dataTable().fnDestroy();
    $('.excel #file').val("");
    $('.excel #filename').html("");
    this.tableTarget.innerHTML = "" ;

    table = $('.data-table-sms').dataTable( {
      "ordering": false,
      "paging": $('.data-table.no-pagination').length == 0,
      "language": {
        "url": '//cdn.datatables.net/plug-ins/1.13.7/i18n/pt-PT.json',
      }
    } );
  }
  
  async selectAll(event) {
    const tab = event.currentTarget.dataset.tab;
    const checked = event.currentTarget.checked;
    $("." + tab + " .sms-option").prop('checked', checked);
  }
}