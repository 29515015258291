
var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
$(document).on("turbolinks:load", function() {
  var table;
  setTimeout(function(){
    table = $('.poll-options-table').DataTable( {
    "ordering": false,
    "paging": false,
    "language": {
      "url": '//cdn.datatables.net/plug-ins/1.13.7/i18n/pt-PT.json',
    }
    } )
  }, 200);
  

  $(document).on('click', '.remove-existing-option', function(){
    let optionId = $(this).parents('.custom-table-row').find('.option-id').clone();
    let optionDestroy = optionId.clone();
    optionDestroy.val('1');
    optionDestroy.attr('name', 'poll[poll_options_attributes][][_destroy]');
    $('.poll-form').append(optionId);
    $('.poll-form').append(optionDestroy);
    //$(this).parents('.custom-table-row').remove();
    
    table.row($(this).parents('.custom-table-row')).remove().draw();
    
    $('.poll-options-table tbody tr.custom-table-row').each( function(index, element){
      console.log($(element).find('.option-index'));
      $(element).find('.option-index').val(index+1);
    })
  });
  
  $(document).on('click', '.remove-option', function(){
    console.log($(this).parents('.custom-table-row'));
    //$(this).parents('.custom-table-row').remove();
    table.row($(this).parents('.custom-table-row')).remove().draw();

    $('.poll-options-table tbody tr.custom-table-row').each( function(index, element){
      console.log($(element).find('.option-index'));
      $(element).find('.option-index').val(index+1);
    })
  });

  $('#addFormModal .add-option').on('click', function(e){
    if ($('#addFormModal .option-title').val() && $('#addFormModal .option-sms-text').val())
    {
      e.preventDefault();
      console.log($('#addFormModal .option-index').val());
      let optionIndex = parseInt($('#addFormModal .option-index').val());
      var $template_row = $('.template-row').clone();
      if(optionIndex > 0){
        $template_row = $('.poll-options-table tbody tr.custom-table-row').eq(optionIndex - 1);
        console.log( $template_row);
      }
      else{
        $template_row = $('.template-row').clone();
        $template_row.find('.option-index').val($('.poll-options-table tbody tr.custom-table-row').length +1);
      }
      $template_row.removeClass("template-row d-none");
      $template_row.find('.option-title').val($('#addFormModal .option-title').val());
      $template_row.find('.option-title-label').text($('#addFormModal .option-title').val());
      $template_row.find('.option-sms-text').val($('#addFormModal .option-sms-text').val());
      $template_row.find('.option-sms-text-label').text($('#addFormModal .option-sms-text').val());
      // $('.poll-options-table tbody').append($template_row);
  
      if(optionIndex > 0){
        table.draw();
      }
      else{
        table.row.add($($template_row)).draw();
      }
      $('#addFormModal').modal('hide');
    }
  });
  //$('.poll-options-table').
});
var last_result;
class Vote {
  constructor() {
  }
  openVote(id) {
    vote.showLoading();
    $(".sidebar .poll-status").addClass('d-none');
    $(".sidebar .poll-action").addClass('d-none');
    const xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
      let result = JSON.parse(this.response);
      $(".sidebar .poll-form").attr("action", "/polls/" + result.id);
      vote.showVote(result);
      vote.hideLoading();
      last_result = result;
    }
    let url = id ? "/polls/" + id.toString() + ".json" : "/polls.json"
    xhttp.open("GET", url);
    xhttp.send();
  }
  reloadVote() {
    if ($('.sidebar #poll').hasClass('d-none')){
      $('.navbar .nav-link-user:has(.nav-link[href="#poll"])').addClass('notify');
    }
    else{
      vote.openVote();
    }
  }
  clearAll() {
    $(".sidebar .poll-status").addClass('d-none');
    $(".sidebar #poll .poll-title").html("");
    $(".sidebar #poll .poll-container").html('');
    $(".sidebar .poll-action").addClass('d-none');
    $(".sidebar .poll-action.result .total-users, .sidebar .poll-action.result .total-votes").text("");
  }
  showVote(result) {
    console.log(result.status);
    vote.clearAll();
    switch (result.status) {
      case 0:
        $(".sidebar .poll-status.pending").removeClass('d-none');
        $(".sidebar #poll .poll-title").html("");
        $(".sidebar #poll .poll-description").removeClass('d-none');
        $(".sidebar #poll .poll-container").html('');
        $(".sidebar .poll-action.start").removeClass('d-none');
        $(".sidebar .poll-status-attr").val(1);
        break;
      case 1:
        $(".sidebar .poll-status.running").removeClass('d-none');
        $(".sidebar #poll .poll-title").html(result.title);
        $(".sidebar #poll .poll-description").removeClass('d-none');
        $(".sidebar #poll .poll-container").html(result.options);
        $(".sidebar .poll-action.stop").removeClass('d-none');
        $(".sidebar.sidebar-admin .poll-action.result .total-votes").text(result.total_votes);
        $(".sidebar.sidebar-admin .poll-action.result .total-users").text(result.total_users);
        $(".sidebar.sidebar-admin .poll-action.result").removeClass('d-none');
        $(".sidebar .poll-status-attr").val(2);
        break;
      case 2:
        $(".sidebar .poll-status.closed").removeClass('d-none');
        $(".sidebar #poll .poll-title").html(result.title);
        $(".sidebar #poll .poll-description").addClass('d-none');
        $(".sidebar #poll .poll-container").html("<span>Resultado</span><div class='card'>" + result.options + "</div>");
        $(".sidebar .poll-action.result .total-votes").text(result.total_votes);
        $(".sidebar .poll-action.result .total-users").text(result.total_users);
        $(".sidebar .poll-action.result .total-abs").text(result.total_abs);
        $(".sidebar .poll-action.result").removeClass('d-none');
        $(".sidebar .poll-status-attr").val(4);
        break;
      case 3:
        $(".sidebar .poll-status.closed").removeClass('d-none');
        $(".sidebar #poll .poll-title").html(result.title);
        $(".sidebar #poll .poll-description").addClass('d-none');
        $(".sidebar #poll .poll-container").html("<span>Resultado</span><div class='card'>" + result.options + "</div>");
        $(".sidebar .poll-action.result .total-votes").text(result.total_votes);
        $(".sidebar .poll-action.result .total-users").text(result.total_users);
        $(".sidebar .poll-action.result .total-abs").text(result.total_abs);
        $(".sidebar .poll-action.result").removeClass('d-none');
        $(".sidebar .poll-status-attr").val(4);
        
      break;
      default:
        $(".sidebar .poll-status.pending").removeClass('d-none');
        $(".sidebar #poll .poll-title").html("");
        $(".sidebar #poll .poll-description").removeClass('d-none');
        $(".sidebar #poll .poll-container").html('');
        break;
    }
  }
  requestVote() {
    vote.showLoading();
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/polls.json', true);
    xhr.onload = function () {
        // do something to response
        let result = JSON.parse(this.response);
        vote = new Vote(result.current, result.my_turn, result.vote_id);
        vote.displayVoteOrder();
        vote.hideLoading();
    };
    xhr.send(new FormData());
  }
  displayVoteOrder() {
    $('.turn-to-vote .voteing-current').val(vote.current);
    $('.turn-to-vote .voteing-my_turn').val(vote.my_turn);

    $('.ask-to-vote').addClass('d-none');
    $('.turn-to-vote').removeClass('d-none');
  }
  cancelRequest() {
    vote.showLoading();
    var xhr = new XMLHttpRequest();
    xhr.open('DELETE', '/polls/' + vote.vote_id + ".json", true);
    xhr.onload = function () {
        // do something to response
        console.log(this.responseText);
        vote.openVote()
        vote.hideLoading();
    };
    xhr.send(new FormData());
  }
  showLoading() {
    $(".sidebar-loading").removeClass("d-none");
  }
  hideLoading() {
    setTimeout(()=>{
      $(".sidebar-loading").addClass("d-none");
    }, 300);
  }
}

var vote = new Vote();
export default Vote; 